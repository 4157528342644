import * as Msdyn365 from '@msdyn365-commerce/core';
import * as React from 'react';
import {ICheckoutConfig} from './../../definition-extensions/checkout.ext.props.autogenerated';
export interface IStepperProps {
    config: ICheckoutConfig;
    salesOrderStatus:number|undefined;
}
export const Stepper = ({config, salesOrderStatus}:IStepperProps) => {
    let currentStep: number;
    if (!salesOrderStatus) {
        currentStep = 1;
    } else if (salesOrderStatus === 1 || salesOrderStatus === 2 || salesOrderStatus === 3 || salesOrderStatus === 4) {
        currentStep = 2;
    } else {
        currentStep = 3;
    }
    if(!salesOrderStatus || (salesOrderStatus && salesOrderStatus < 10)) {
        return (
            <div className={'ms-stepper'}>
                <div className={`ms-stepper--step1 ${currentStep === 1 && 'current'}`}>
                    <span className={'icon'}/>
                    <div>
                        <h6 className={'ms-stepper--title'}>{config.checkoutStep1Title}</h6>
                        <Msdyn365.RichTextComponent
                            className={'ms-stepper--description'}
                            text={config.checkoutStep1Desc}
                        />
                    </div>
                </div>
                <div className={`ms-stepper--step2 ${currentStep === 2 && 'current'}`}>
                    <span className={'icon'}/>
                    <div>
                        <h6 className={'ms-stepper--title'}>{config.checkoutStep2Title}</h6>
                        <Msdyn365.RichTextComponent
                            className={'ms-stepper--description'}
                            text={config.checkoutStep2Desc}
                        />
                    </div>
                </div>
                <div className={`ms-stepper--step3 ${currentStep === 3 && 'current'}`}>
                    <span className={'icon'}/>
                    <div>
                        <h6 className={'ms-stepper--title'}>{config.checkoutStep3Title}</h6>
                        <Msdyn365.RichTextComponent
                            className={'ms-stepper--description'}
                            text={config.checkoutStep3Desc}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return <div/>;
    }

};